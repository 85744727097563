import React, { useEffect, useMemo, useState } from 'react';
import { graphql } from 'gatsby';
import shuffle from '../fisherYatesShuffle'
import Layout from '../components/layout';
import PostList, { StyledDevList, DevListItem } from '../components/post-list';
import styled from 'styled-components';
import {StyledLink, StyledA } from '../components/styled-link';
import FlatList from 'flatlist-react';



const HomePage = ({ data }) => {
  const posts = data.allMarkdownRemark.nodes;
  const intro = data.markdownRemark.html;
  const title = data.markdownRemark.frontmatter.title;
  const developersEdge = data.allDataJson.edges || [];
  const developers = developersEdge.map(item => (item || {}).node);
  

  const developerIncrementAmount = 10
  const [end, setEnd] = useState(1);
  const shuffledDevelopers = useMemo(() => {
    console.log('memo'); return shuffle(developers)}, []);
  const nextSetEnd = shuffledDevelopers.length >= end + developerIncrementAmount ? end + developerIncrementAmount : shuffledDevelopers.length;
  const showNext = end != shuffledDevelopers.length;
  useEffect(()=>{
    setEnd(developerIncrementAmount);
  }, [])
  const defaultImage = "https://uiroundup.com/static/noImage-f817c2f8ec396cf42f64bafc2c421111.svg"
  const renderPerson = ({ 
    name, url, image= defaultImage,
  ...remaining }) => <DevListItem {...remaining} key={name+url} name={name} slug={url} image={image ? image : defaultImage} />

  const ViewMore = ({ children, onClick, to }) => <StyledLink
    css={`
          display: block;
          margin-top: var(--size-800);
          margin-bottom: var(--size-800);
          margin-left: auto;
          margin-right: auto;
          width: fit-content;
      `}
    onClick={onClick}
    to={to}
  >{children}</StyledLink>


  return (
    <Layout title={title}>
      <Intro
        dangerouslySetInnerHTML={{
          __html: intro,
        }}
      />
      <Section>
        <Main>
          <StyledDevList>
            <FlatList
              list={shuffledDevelopers}
              renderItem={renderPerson}
              limit={`1, ${end}`}
              renderWhenEmpty={() => <div>List is empty!</div>}
            />
          </StyledDevList>
          {showNext ? <StyledA style={{
            display: 'block',
            margin: 'var(--size-800) auto',
            width: 'fit-content'
          }}
          onClick={() => setEnd(nextSetEnd) }>Load {end} - {nextSetEnd}</StyledA> :
          <div>That's all folks, but you could <StyledLink to="/contact">That's it folks. </StyledLink></div>
          
          }
        </Main>
        <Aside>
          <PostList posts={posts} />
          <ViewMore to="/blog">View All Posts</ViewMore>
        </Aside>
      </Section>
    </Layout >
  );
};

export default HomePage;
const Main = styled.main`
width: 100%;
`
const Section = styled.section`
 padding: 0;
  list-style: none;
  display: grid;
  justify-items: center;
  grid-gap: var(--size-600);
  grid-template-columns: minmax(300px, 1fr) minmax(30ch, 50ch);

  @media screen and (max-width: 1060px) {
    & {
      display: block;
    }
  }
`
const Aside = styled.aside``;

const Intro = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 60ch;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  margin-top: var(--size-800);
  margin-bottom: var(--size-900);
  text-align: center;

  & p {
    text-transform: capitalize;
    font-size: var(--size-400);
  }

  @media screen and (max-width: 700px) {
    & h1 {
      font-size: var(--size-700);
    }
  }
`;

export const pageQuery = graphql`
  query($slug: String!) {
  
    allDataJson {
      edges {
        node {
          id
          mobileImage
          image
          url
          title
          name
          description
        }
      }
    }

    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { fields: { contentType: { eq: "posts" } } }
      sort: { order: DESC, fields: frontmatter___date }
      limit: 9
    ) {
      nodes {
        fields {
          slug
        }
        excerpt
        timeToRead
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          description
          title
          tags
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
